import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

export function ApiKeys() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="flex-parent flex justify-between px-11 py-6">
                <div>
                    <h1 className="overview-title  text-3xl font-medium">wpe api keys will be stored here </h1>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
            </div>
        </motion.div>
    );

}


import React from 'react';


const SiteModal = ({ site, onClose }) => {
    const modalStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        border: '1px solid #ccc',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    };

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        fontSize: '20px'
    };

    return (
        <>
            <div style={overlayStyle} onClick={onClose}></div>
            <div style={modalStyle}>
                <button style={closeButtonStyle} onClick={onClose}>&times;</button>
                <h2> Site ID: {site.wp_engine_site.site_id}</h2>
                <p style={{ marginTop: '10px' }}>Account ID: {site.wp_engine_site.account_id}</p>
            </div>
        </>
    );
};

export default SiteModal;

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';



export function Payers() {
  const [editorHtml, setEditorHtml] = useState('');

  function handleChange(html) {
    setEditorHtml(html);
  }

  function submitText() {
    fetch('http://localhost/BSA-shell-code/src/updateData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: 7, // Here goes the id that you want to update
        text: editorHtml,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log("Text updated successfully");
        } else {
          console.log("Error updating text");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  useEffect(() => {
    const tableName = 'wysiwyg';
    const recordId = '7';
    const url = `http://localhost/BSA-shell-code/src/getData.php?table=${encodeURIComponent(tableName)}&id=${encodeURIComponent(recordId)}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data && data[0] && data[0].text) {
          setEditorHtml(data[0].text);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div>
        <motion.div animate className="flex justify-between py-6">
          <div>
            <h1 className="text-2xl font-medium">Settings</h1>
            <span>This will be the text editor</span>
          </div>
        </motion.div>

        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex flex-col items-start">
          <div className="self-center w-3/4 mb-4">
            <ReactQuill value={editorHtml} onChange={handleChange} style={{ height: '450px' }} />
          </div>
          <div className="self-center w-full flex justify-center mt-10">
            <button onClick={submitText} className="bg-blue-500 text-white px-6 py-2 rounded">
              Submit
            </button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

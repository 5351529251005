import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

export function Products() {
    const [ipAccessData, setIpAccessData] = useState([]);

    useEffect(() => {
        const tableName = 'wysiwyg';
        const recordId = '7';
        const url = `http://localhost/BSA-shell-code/src/getData.php?table=${encodeURIComponent(tableName)}&id=${encodeURIComponent(recordId)}`;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data && Array.isArray(data) && data.length > 0) {
                    setIpAccessData(data);
                } else {
                    throw new Error('No data returned');
                }
            })
            .catch(error => {
                console.error("Error al obtener datos:", error);
            });
    }, []);




    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="flex-parent flex justify-between px-11 py-6">
                <div>
                    <h1 className="overview-title  text-3xl font-medium">Hi this is the products</h1>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>

                </div>
            </div>
        </motion.div>
    );

}


import React from "react";
import { Input } from "../App";
import { NavItem } from "./components";
import { useRoutes } from "hookrouter";
import { Troops } from "./troops";
import { Transactions } from "./transactions";
import { Payers } from "./payers";
import { Users } from "./users";
import { AnimatePresence, motion } from "framer-motion";
import { Products } from "./products"
import { SavedCards } from "./cards";
import { ApiKeys } from "./settings";

import {
  Logout,
  Users as UsersIcon,
} from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./plans";
import { IconEnroll, IconSetting, IconOverview, IconTag } from "../assets/icons/hiking";
import { Unit } from "./unit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payer from "./payer";
import User from "./user";

const routes = {
  "/": () => <Troops />,
  "/sites": () => <Troops />,
  "/site/:siteId": ({ siteId }) => <Unit unitId={siteId} />,
  "/transactions": () => <Transactions />,
  "/payers": () => <Payers />,
  "/payer/:payerId": ({ payerId }) => <Payer payerId={payerId} />,

  "/products": () => <Products />,
  "/cards": () => <SavedCards />,

  "/apikeys": () => <ApiKeys />,


  "/plans": () => <Plans />,
  "/users": () => <Users />,
  "/user/:userId": ({ userId }) => <User userId={userId} />,
};


export default function Main() {
  const routeResult = useRoutes(routes);

  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <div className="fixed h-full pr-10 pb-6">
        <div className="flex flex-col justify-between w-56 h-full text-white rounded-lg bg-firefly">
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img className="w-3/5 mb-4" src={`${process.env.PUBLIC_URL}/TRC_4r-logo_white.webp`} />
            <div className="px-1 py-0.5 bg-red-600 text-sm text-white font-semibold cursor-default">
              billing agency
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60">


            <motion.ul className="flex flex-col">
              <NavItem linkTo="/app/sites" selected={routeResult.type === Troops}>
                {/* <IconOverview className="-ml-3 mr-2" /> */}
                Sites
              </NavItem>

              <NavItem linkTo="/app/apikeys" selected={routeResult.type === ApiKeys}>
                Settings
              </NavItem>

              <NavItem linkTo="/app/plans" selected={routeResult.type === Plans}>
                Customers
              </NavItem>

              <NavItem linkTo="/app/products" selected={routeResult.type === Products}>
                Products
              </NavItem>

              <NavItem linkTo="/app/payers" selected={routeResult.type === Payers}>
                Billing Agreements
              </NavItem>

              <NavItem linkTo="/app/cards" selected={routeResult.type === SavedCards}>
                Saved Cards
              </NavItem>

              <NavItem linkTo="/app/transactions" selected={routeResult.type === Transactions}>
                Transactions
              </NavItem>



            </motion.ul>
          </div>
          <div className="p-6 text-center">
            <div onClick={logout} className="flex justify-center cursor-pointer">
              <Logout className="pr-2" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 text-gray-800" style={{ marginLeft: "244px" }}>
        {/* <Input disabled placeholder="Search Anything Here..." /> */}
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>
      </div>
    </div>
  );

}





import React, { useState, useEffect } from 'react';
import "react-dates/lib/css/_datepicker.css";
import { motion } from "framer-motion";
import { TableIcon, GridIcon, AddnewIcon, FilterIcon } from '../assets/icons/hiking';



export function Transactions() {
  const [tokens, setTokens] = useState([]);
  const [isTableView, setIsTableView] = useState(true);
  const [searchTermState, setSearchTermState] = useState('');
  const [sitesData, setSitesData] = useState([]);

  const toggleView = (viewType) => {
    if (viewType === 'grid') {
      setIsTableView(false);
    } else if (viewType === 'table') {
      setIsTableView(true);
    }
  }

  useEffect(() => {
    const type = "wpengine_site";
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=' + type;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSitesData(data.data.response);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container-tokens"
    >
      <div className="header-tokens">
        <h1 className="title-tokens">Tokens</h1>
        <input
          type="text"
          placeholder="Search for a token"
          className="input-tokens"
          value={searchTermState}
          onChange={(e) => setSearchTermState(e.target.value)}
        />
        <button className="filter-button-token">
          Filter By
          <span style={{ marginLeft: '8px' }}>
            <FilterIcon />
          </span>
        </button>

        <div className="view-switch">
          <button
            className={`view-option ${!isTableView ? 'selected' : ''}`}
            onClick={() => toggleView('grid')}
          >
            <GridIcon selected={!isTableView} />
          </button>
          <button
            className={`view-option ${isTableView ? 'selected' : ''}`}
            onClick={() => toggleView('table')}
          >
            <TableIcon selected={isTableView} />
          </button>
        </div>
        <button className="add-new-token">
          <AddnewIcon className="mr-1" />
          ADD NEW
        </button>
      </div>

      {
        isTableView ? (
          <div className="tokens-table-container">
            <table className="tokens-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Token Name</th>
                  <th>URL</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>State</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sitesData.map((site) => (
                  <tr>
                    {/*  <td>{site.wp_engine_site.name}</td>
                    <td>{site.wp_engine_site.revision}</td>
                    <td>{site.wp_engine_site.group_name}</td>
                    <td>{site.wp_engine_site.created_date ? formatDate(site.wp_engine_site.created_date) : 'No Date'}</td>

              <td>{token.status}</td>
              <td>{token.state}</td> */}
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="tokens-grid">
            {tokens.map((token, index) => (
              <div key={index} className="token-card">
                <span className="state-label-token">{token.state}</span>
                <h2 className="token-name">{token.token_name}</h2>

                <p className="token-url">{token.token_url}</p>

                <hr className="separator-line" />

                <div >
                  {/* <span className="token-type-title">Type</span> */}
                  <span className="token-type">{token.type}</span>
                </div>

                <div className="token-status">
                  {token.status}
                </div>
              </div>

            ))}
          </div>
        )
      }
    </motion.div >
  );
}

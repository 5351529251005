import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { InfoIcon } from "../../assets/icons/hiking";

export default function Plans() {


  const [errorMsg, setErrorMsg] = useState('');
  const handleDescriptionChange = (e) => {
    const value = e.target.value;

    if (value.split(' ').filter(word => word).length > 50) { // This makes sure that the words that count are not blanks.
      setErrorMsg('Your description should not exceed 50 words.');
    } else {
      setErrorMsg('');
    }

    setTokenDescription(value);
  }

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [tokenName, setTokenName] = useState('');
  const [tokenUrl, setTokenUrl] = useState('');
  const [tokenExpiry, setTokenExpiry] = useState('');
  const [tokenDescription, setTokenDescription] = useState('');
  const [tokens, setTokens] = useState([]);


  useEffect(() => {
    const storedTokens = JSON.parse(localStorage.getItem('tokens'));
    if (storedTokens) {
      setTokens(storedTokens);
    }
  }, []);
  const nextStep = () => {
    const newToken = {
      tokenName,
      tokenUrl,
      tokenExpiry,
      tokenDescription
    };
    const updatedTokens = [...tokens, newToken];
    setTokens(updatedTokens);
    localStorage.setItem('tokens', JSON.stringify(updatedTokens));

    // setTokenName('');
    // setTokenUrl('');
    // setTokenExpiry('');
    // setTokenDescription('');

    //if (step < 3) setStep(step + 1);  
  };

  function CircleWithRing({ isActive, children }) {
    return (
      <div className="circle-container">
        <div className={isActive ? "circle active" : "circle"}>{children}</div>
        {isActive && <div className="ring2"></div>}
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-center w-full min-h-screen pt-10"
    >
      <div className="container mx-auto p-6 custom-width">
        <div className="flex justify-start mb-6 space-x-4 -mt-5">
          <CircleWithRing isActive={step === 1}>1</CircleWithRing>
          <CircleWithRing isActive={step === 2}>2</CircleWithRing>
          <CircleWithRing isActive={step === 3}>3</CircleWithRing>
        </div>


        <div className="flex justify-between py-6">
          <div>
            <h1 className="text-4xl font-bold -mt-3">Token Title</h1>
            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
          </div>
        </div>
        {step === 1 && (
          <div>

            <div className="flex justify-between items-center mb-2">
              <label htmlFor="tokenName" className="text-sm font-semibold">Token Title</label>

              <span className="cursor-pointer relative"
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}>
                <InfoIcon />
                <div className={`absolute bottom-full right-0 mb-1 p-2 w-auto whitespace-nowrap bg-white border border-gray-300 text-black text-xs rounded shadow-lg transition-opacity duration-150 ${isTooltipVisible ? 'opacity-100 visibility-visible' : 'opacity-0 visibility-hidden'} z-50`}>
                  Create a simple name that describes where the token will be used.
                </div>


              </span>
            </div>

            <input
              placeholder="EX: TECH US ECOM"
              className="w-full input-style"
              value={tokenName}
              onChange={(e) => setTokenName(e.target.value)}
            />


            <label htmlFor="tokenUrl" className="text-sm font-semibold">Site URL</label>
            <input
              placeholder="Paste full working URL to site here"
              className="mb-2 w-full input-style"
              value={tokenUrl}
              onChange={(e) => setTokenUrl(e.target.value)}
            />

            <label htmlFor="tokenExpiry" className="text-sm font-semibold">Expiration Date</label>
            <input
              placeholder="YYYY-MM-DD (Leave Blank for No Exp)"
              className="mb-2 w-full input-style"
              value={tokenExpiry}
              onChange={(e) => setTokenExpiry(e.target.value)}
            />

            <label htmlFor="tokenDescription" className="text-sm font-semibold">Description</label>
            <textarea
              placeholder="Maximum of 50 words"
              className="mb-2 w-full input-style textarea-style"
              value={tokenDescription}
              onChange={handleDescriptionChange}
            ></textarea>
            <p className="text-red-500">{errorMsg}</p>  {/* error message */}
            <button className="mt-2 save-and-continue text-sm font-semibold" onClick={nextStep}>SAVE & CONTINUE</button>
          </div>
        )}
        {step === 2 && (
          <div>
            <label htmlFor="tokenName" className="text-sm font-semibold">Token Name</label>
            <input placeholder="Write Name Here" className="mb-2 w-full input-style" />
            <button className="mt-2 save-and-continue text-sm font-semibold" onClick={nextStep}>SAVE & CONTINUE</button>
          </div>
        )}
        {step === 3 && (
          <div>
            <label htmlFor="tokenName" className="text-sm font-semibold">Description</label>
            <input placeholder="Maximim of 50 words" className="mb-2 w-full input-style" />
            <button className="mt-2 save-and-continue text-sm font-semibold" onClick={nextStep}>SAVE & CONTINUE</button>
          </div>
        )}
      </div>
    </motion.div >
  );
}

import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import SiteModal from './popup';

export function Troops() {
  const [sitesData, setSitesData] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const type = "wpengine_site";
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=' + type;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSitesData(data.data.response);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };



  const handleSiteClick = (site) => {
    setSelectedSite(site);
  };

  const filterSites = (sites, term) => {
    if (!term.trim()) return sites;
    return sites.filter(site =>
      site.wp_engine_site.name.toLowerCase().includes(term.toLowerCase()) ||
      (site.wp_engine_site.group_name && site.wp_engine_site.group_name.toLowerCase().includes(term.toLowerCase()))
    );
  };




  function repeatElements(array, count) {
    return array.flatMap(item => Array(count).fill(item));
  }

  const repeatedSites = repeatElements(sitesData, 20);
  const filteredSites = filterSites(repeatedSites, searchTerm);



  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const totalPages = Math.ceil(filteredSites.length / pageSize);

  const currentSites = filteredSites.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const goToNextPage = () => setCurrentPage(currentPage => Math.min(currentPage + 1, totalPages));
  const goToPreviousPage = () => setCurrentPage(currentPage => Math.max(currentPage - 1, 1));

  // logic to generate the numbers of the pages 
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // functions to go to a Specific page 
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber)
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* header */}
      <div className="header-tokens">
        <h1 className="title-tokens">Sites</h1>

        <input
          type="text"
          placeholder="Search for a Site"
          className="input-tokens"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />


        {/* <div className="view-switch">
          <button
            className="view-option"
            onClick={() => { }} // logic
          >
            <GridIcon />
          </button>
          <button
            className="view-option"
            onClick={() => { }} // logic
          >
            <TableIcon />
          </button>
        </div> */}

        {/* <button className="add-new-token">
          <AddnewIcon className="mr-1" />
          ADD NEW
        </button> */}


      </div>


      <div>
        {currentSites.map((site, index) => (
          <div
            key={`${site.id}-${index}`}
            style={{
              cursor: 'pointer',
              border: '1px solid #ccc',
              padding: '20px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
              marginBottom: index === filteredSites.length - 1 ? '24px' : '8px',
              borderRadius: '4px'
            }}
            onClick={() => handleSiteClick(site)}
          >
            <h2 className="text-xl font-bold mb-2">{site.wp_engine_site.name}</h2>
            {/* Shows group_name only if it is not empty*/}
            {site.wp_engine_site.group_name && (
              <p>Group: {site.wp_engine_site.group_name}</p>
            )}
            <p>{formatDate(site.wp_engine_site.created_date)}</p>
          </div>
        ))}
      </div>
      {filteredSites.length > 0 && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}>
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            style={{
              margin: '0 5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px 10px',
            }}
          >
            Previous
          </button>

          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => goToPage(number)}
              style={{
                fontWeight: currentPage === number ? 'bold' : 'normal',
                margin: '0 5px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '5px 10px',
                backgroundColor: currentPage === number ? '#f0f0f0' : 'transparent',
              }}
            >
              {number}
            </button>
          ))}

          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px 10px',
            }}
          >
            Next
          </button>
        </div>
      )}
      {selectedSite && <SiteModal site={selectedSite} onClose={() => setSelectedSite(null)} />}
    </motion.div>
  );
}
